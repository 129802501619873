<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="selectedCountry"
          :disabled="isUpdating || (copyResidence && copyable) || disabled"
          :items="countriesList"
          filled
          color="blue-grey lighten-2"
          :label="$t('PAGES.CITIZENS.FORM.MANAGE.COUNTRIES')"
          item-text="denomination"
          item-value="id"
          clearable
          @change="changeLocation($event, 'country')"
        >
          <template slot="selection" slot-scope="{ item, tile }">
            {{ item.denomination }} {{ tile }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.denomination }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="selectedRegion"
          :disabled="foreignCountry || (copyResidence && copyable) || disabled"
          :items="regionsLocalList"
          filled
          color="blue-grey lighten-2"
          :label="$t('PAGES.CITIZENS.FORM.MANAGE.REGIONS')"
          item-text="denomination"
          item-value="id"
          clearable
          @change="changeLocation($event, 'region', 'Birth')"
        >
          <template slot="selection" slot-scope="{ item, tile }">
            {{ item.denomination }} {{ tile }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.denomination }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="selectedState"
          :disabled="foreignCountry || (copyResidence && copyable) || disabled"
          :items="statesLocalList"
          filled
          color="blue-grey lighten-2"
          :label="$t('PAGES.CITIZENS.FORM.MANAGE.STATES')"
          item-text="denomination"
          item-value="id"
          clearable
          @change="changeLocation($event, 'state', 'Birth')"
        >
          <template slot="selection" slot-scope="{ item, tile }">
            {{ item.denomination }} {{ tile }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.denomination }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="selectedCity"
          :disabled="foreignCountry || (copyResidence && copyable) || disabled"
          :items="citiesLocalList"
          filled
          color="blue-grey lighten-2"
          :label="$t('PAGES.CITIZENS.FORM.MANAGE.CITIES')"
          item-text="denomination"
          item-value="id"
        >
          <template slot="selection" slot-scope="{ item, tile }">
            {{ item.denomination }} {{ tile }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.denomination }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import config from "@/core/config/general.config.json";
import {
  FETCH_REGIONS_LIST,
  RESET_REGIONS_LIST
} from "@/core/services/store/regions.module";
import {
  FETCH_STATES_LIST,
  RESET_STATES_LIST
} from "@/core/services/store/states.module";
import {
  FETCH_CITIES_LIST,
  RESET_CITIES_LIST
} from "@/core/services/store/cities.module";

export default {
  name: "LocationBoxes",
  data() {
    return {
      regions: this.regionsList,
      states: this.statesList,
      cities: this.citiesList,
      countryFlag: true,
      regionFlag: true,
      stateFlag: true,
      cityFlag: true,
      regions_filters: {
        query: [],
        filters: {
          "country.id": {
            _eq: config.location.country
          }
        },
        sort: {},
        page: 1
      },
      states_filters: {
        query: [],
        filters: {
          "region.id": {
            _eq: config.location.region
          }
        },
        sort: {},
        page: 1
      },
      cities_filters: {
        query: [],
        filters: {
          "state.id": {
            _eq: config.location.state
          }
        },
        sort: {},
        page: 1
      }
    };
  },
  props: {
    countriesList: Array,
    selectedCountry: Number,
    selectedRegion: Number,
    selectedState: Number,
    selectedCity: Number,
    resident: Boolean,
    copyResidence: Boolean,
    copyable: Boolean,
    city: {},
    abroad: {},
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["regionsList", "statesList", "citiesList"]),
    regionsLocalList() {
      return this.elaborateRegions();
    },
    statesLocalList() {
      return this.elaborateStates();
    },
    citiesLocalList() {
      return this.elaborateCities();
    },
    foreignCountry() {
      return this.selectedCountry == 380 ? false : true;
    }
  },
  methods: {
    elaborateRegions() {
      if (
        this.countryFlag &&
        this.regionsList != undefined &&
        this.regionsList.length > 0
      ) {
        this.setCountryFlag(false);
        this.setRegions(this.regionsList);
        return this.regionsList;
      } else {
        if (!this.countryFlag) {
          return this.regions;
        }
        if (this.regions != undefined && this.regions.length == undefined) {
          return this.regionsList;
        } else {
          return this.regions;
        }
      }
    },
    elaborateStates() {
      if (
        this.regionFlag &&
        this.statesList != undefined &&
        this.statesList.length > 0
      ) {
        this.setRegionFlag(false);
        this.setStates(this.statesList);
        return this.statesList;
      } else {
        if (!this.regionFlag) {
          return this.states;
        }
        if (this.states != undefined && this.states.length == undefined) {
          return this.statesList;
        } else {
          return this.states;
        }
      }
    },
    elaborateCities() {
      if (
        this.stateFlag &&
        this.citiesList != undefined &&
        this.citiesList.length > 0
      ) {
        this.setStateFlag(false);
        this.setCities(this.citiesList);
        return this.citiesList;
      } else {
        if (!this.stateFlag) {
          return this.cities;
        }
        if (this.cities != undefined && this.cities.length == undefined) {
          return this.citiesList;
        } else {
          return this.cities;
        }
      }
    },
    setRegionsFilter() {
      return {
        query: [],
        filters:
          this.selectedCountry != null
            ? {
                "country.id": {
                  _eq: this.selectedCountry
                }
              }
            : {},
        sort: {},
        page: 1
      };
    },
    setStatesFilter() {
      return {
        query: [],
        filters:
          this.selectedRegion != null
            ? {
                "region.id": {
                  _eq: this.selectedRegion
                }
              }
            : {},
        sort: {},
        page: 1
      };
    },
    setCitiesFilter() {
      return {
        query: [],
        filters:
          this.selectedState != null
            ? {
                "state.id": {
                  _eq: this.selectedState
                }
              }
            : {},
        sort: {},
        page: 1
      };
    },
    setCountryFlag(value) {
      this.countryFlag = value;
    },
    setRegionFlag(value) {
      this.regionFlag = value;
    },
    setStateFlag(value) {
      this.stateFlag = value;
    },
    setRegions(values) {
      this.regions = values;
    },
    setStates(values) {
      this.states = values;
    },
    setCities(values) {
      this.cities = values;
    },
    changeLocation: function (evt, type) {
      switch (type) {
        case "country":
          this.selectedCountry = evt;
          this.selectedRegion = null;
          this.selectedState = null;
          this.selectedCity = null;
          this.regions = {};
          this.states = {};
          this.cities = {};
          break;
        case "region":
          this.selectedRegion = evt;
          this.selectedState = null;
          this.selectedCity = null;
          this.states = {};
          this.cities = {};
          break;
        case "state":
          this.selectedState = evt;
          this.selectedCity = null;
          this.cities = {};
          break;
      }
    }
  },
  mounted() {
    if (this.regionsList == undefined || this.regionsList.length == 0) {
      this.$store.dispatch(FETCH_REGIONS_LIST, this.regions_filters);
    }
    if (this.statesList == undefined || this.statesList.length == 0) {
      this.$store.dispatch(FETCH_STATES_LIST, this.states_filters);
    }
    if (this.citiesList == undefined || this.citiesList.length == 0) {
      this.$store.dispatch(FETCH_CITIES_LIST, this.cities_filters);
    }

    this.regions = Object.assign({}, this.regionsList);
    this.states = Object.assign({}, this.statesList);
    this.cities = Object.assign({}, this.citiesList);
  },
  watch: {
    city: function () {
      if (this.city != undefined || this.abroad != undefined) {
        if (this.abroad != undefined) {
          this.selectedCountry = this.abroad;
        } else {
          if (this.city.state != undefined) {
            this.selectedCountry = this.city.state.region.country.id;
          }
        }
      }
    },
    selectedCountry: function () {
      this.$store.dispatch(RESET_REGIONS_LIST);
      this.setCountryFlag(true);
      if (this.selectedCountry == 380) {
        this.$store.dispatch(RESET_STATES_LIST);
        this.$store.dispatch(RESET_CITIES_LIST);
        this.$store.dispatch(FETCH_REGIONS_LIST, this.setRegionsFilter());
        this.$store.dispatch(FETCH_STATES_LIST, this.setStatesFilter());
        this.$store.dispatch(FETCH_CITIES_LIST, this.setCitiesFilter());
      } else {
        this.$emit("update:resident", false);
      }
      this.$emit("update:selected-country", this.selectedCountry);
    },
    selectedRegion: function () {
      let states_filters = {
        query: [],
        filters: {
          "region.id": {
            _eq: this.selectedRegion
          }
        },
        sort: {},
        page: 1
      };
      if (this.selectedRegion == null) {
        states_filters.filters = {};
        return;
      }
      this.$store.dispatch(RESET_STATES_LIST);
      this.setRegionFlag(true);
      if (this.selectedCountry == 380) {
        this.$store.dispatch(FETCH_STATES_LIST, states_filters);
      }
      this.$emit("update:selectedRegion", this.selectedRegion);
    },
    selectedState: function () {
      let cities_filters = {
        query: [],
        filters: {
          "state.id": {
            _eq: this.selectedState
          }
        },
        sort: {},
        page: 1
      };
      if (this.selectedState == null) {
        cities_filters.filters = {};
        return;
      }
      this.$store.dispatch(RESET_CITIES_LIST);
      this.setStateFlag(true);
      if (this.selectedCountry == 380) {
        this.$store.dispatch(FETCH_CITIES_LIST, cities_filters);
      }
      this.cities = Object.assign({}, this.citiesList);
      this.$emit("update:selectedState", this.selectedState);
    },
    selectedCity: function () {
      this.$emit("update:selectedCity", this.selectedCity);
    },
    resident: function () {
      if (this.resident) {
        this.selectedCountry = config.location.country;
        this.selectedRegion = config.location.region;
        this.selectedState = config.location.state;
        this.selectedCity = config.location.city;
      } else {
        this.selectedCountry = null;
        this.selectedRegion = null;
        this.selectedState = null;
        this.selectedCity = null;
      }
    }
  }
};
</script>
