var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.isUpdating || (_vm.copyResidence && _vm.copyable) || _vm.disabled,"items":_vm.countriesList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.CITIZENS.FORM.MANAGE.COUNTRIES'),"item-text":"denomination","item-value":"id","clearable":""},on:{"change":function($event){return _vm.changeLocation($event, 'country')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var tile = ref.tile;
return [_vm._v(" "+_vm._s(item.denomination)+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.denomination)+" ")]}}]),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.foreignCountry || (_vm.copyResidence && _vm.copyable) || _vm.disabled,"items":_vm.regionsLocalList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.CITIZENS.FORM.MANAGE.REGIONS'),"item-text":"denomination","item-value":"id","clearable":""},on:{"change":function($event){return _vm.changeLocation($event, 'region', 'Birth')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var tile = ref.tile;
return [_vm._v(" "+_vm._s(item.denomination)+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.denomination)+" ")]}}]),model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.foreignCountry || (_vm.copyResidence && _vm.copyable) || _vm.disabled,"items":_vm.statesLocalList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.CITIZENS.FORM.MANAGE.STATES'),"item-text":"denomination","item-value":"id","clearable":""},on:{"change":function($event){return _vm.changeLocation($event, 'state', 'Birth')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var tile = ref.tile;
return [_vm._v(" "+_vm._s(item.denomination)+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.denomination)+" ")]}}]),model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.foreignCountry || (_vm.copyResidence && _vm.copyable) || _vm.disabled,"items":_vm.citiesLocalList,"filled":"","color":"blue-grey lighten-2","label":_vm.$t('PAGES.CITIZENS.FORM.MANAGE.CITIES'),"item-text":"denomination","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var tile = ref.tile;
return [_vm._v(" "+_vm._s(item.denomination)+" "+_vm._s(tile)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.denomination)+" ")]}}]),model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }