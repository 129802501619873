/**
 * General helpers
 */
import moment from "moment";
import config from "@/core/config/general.config.json";
const generalHelper = {
    dateFormatter: function (date) {
        return date
            ? moment(String(date)).format(
                config.date.formatter
            )
            : "";
    }
};
export default generalHelper;
