<template>
  <div></div>
</template>
<script>
import Swal from "sweetalert2";

export default {

  name: "DialogMessage",
  data() {
    return {};
  },
  method: {},
  watch: {
    dialog: function () {
      var self = this
      if (this.dialog) {
        Swal.fire({
          text: this.vars.message.title,// + '\n'+ this.vars.message.text ,
          icon: this.vars.type,
          buttonsStyling: false,
          confirmButtonText: this.$t("COMMON.BUTTON.OK"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(function () {
          self.$emit('update:dialog', false)
          if(self.vars.route != false) {
            self.$router.push({name: self.vars.route});
          }
        });
      }
    }
  },
  props: ["vars", "dialog"],
};
</script>
