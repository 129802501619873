<template>
  <div>
    <v-menu
      ref="dateMenu"
      v-model="date"
      :close-on-content-click="false"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
      max-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          clearable
          ret
          filled
          dense
          :label="label"
          readonly
          :disabled="disabled"
          hide-details
          v-bind="attrs"
          v-on="on"
          append-icon="calendar_today"
          @click:clear="onClearDateClicked()"
        ></v-text-field>
        {{ attr }}
      </template>
      <v-date-picker
        v-model="initialDate"
        :first-day-of-week="1"
        locale="it-it"
        actions
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="$refs.dateMenu.save(initialDate)">
          {{ $t("COMMON.DIALOG.OK") }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import general from "@/core/helper/general.helper.js";
export default {
  name: "DateBox",
  data() {
    return {
      date: ""
    };
  },
  computed: {
    dateFormatted() {
      return general.dateFormatter(this.initialDate);
    }
  },
  props: {
    finalDate: {},
    initialDate: {},
    label: {},
    disabled: Boolean,
    min: {},
    max: {}
  },
  methods: {
    onClearDateClicked() {
      this.date = "";
    }
  },
  watch: {
    initialDate: function () {
      this.$emit("update:final-date", this.initialDate);
    }
  }
};
</script>
