<template>
  <div>
    <div class="row" v-show="false">
      <div class="col-md-12">
        <b-alert
            show
            variant="light"
            class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Tools/Compass.svg"/>
            </span>
          </div>
          <div class="alert-text">
            <b>Data tables</b> The <code>v-data-table</code> component is used
            for displaying tabular data. Features include sorting, searching,
            pagination, inline-editing, header tooltips, and row selection.
            <a
                class="font-weight-bold"
                href="https://vuetifyjs.com/en/components/data-tables"
                target="_blank"
            >
              See documentation.
            </a>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-card :loading="citizenSaveLoading">
          <template slot="progress">
            <v-overlay absolute class="d-flex flex-column text-center">
              <div>
                <v-progress-circular
                    size="150"
                    color="#1BC5BD "
                    :value="citizenSaveLoading"
                    indeterminate
                    ref="savingLoader"
                >
                  <span>{{
                      $t("PAGES.CITIZENS.FORM.MANAGE.SAVING_LOADING")
                    }}</span>
                </v-progress-circular>
              </div>
            </v-overlay>
          </template>
          <v-card-title>
            {{ $t("PAGES.CITIZENS.EDIT.TITLE_PERSON") }}
          </v-card-title>
          <b-card-body>
            <v-card-text>
              <v-form ref="new_citizen_form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="singleItem.name"
                        :disabled="disabled"
                        ret
                        filled
                        dense
                        :rules="citizenRequiredRule"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.NAME')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="singleItem.surname"
                        ret
                        filled
                        dense
                        :disabled="disabled"
                        :rules="citizenRequiredRule"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.SURNAME')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="singleItem.fiscal_code"
                        filled
                        dense
                        :disabled="disabled"
                        :rules="fiscalCodeRule"
                        v-on:blur="checkFiscalCode"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.FISCAL_CODE')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-toolbar-title
                >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.BIRTH_DATA") }}
                </v-toolbar-title>
                <v-row>
                  <v-col cols="12" md="3">
                    <DateBox
                        :disabled="disabled"
                        :final-date.sync="singleItem.date_of_birth"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.DATE_OF_BIRTH')"
                    ></DateBox>
                  </v-col>
                </v-row>
                <LocationBoxes
                    :abroad.sync="selectedAbroadCountryBirth"
                    :countries-list="countriesList"
                    :selectedCountry.sync="selectedCountryBirth"
                    :selectedRegion.sync="selectedRegionBirth"
                    :selectedState.sync="selectedStateBirth"
                    :selectedCity.sync="selectedCityBirth"
                    :disabled="disabled"
                ></LocationBoxes>
                <v-divider></v-divider>
                <v-toolbar-title
                >{{ $t("PAGES.RESERVATIONS.NEW.STEPS.CITIZEN.RESIDENCE_DATA") }}
                </v-toolbar-title>
                <LocationBoxes
                    :abroad.sync="selectedAbroadCountryResidence"
                    :resident.sync="resident"
                    :copyable="false"
                    :copy-residence.sync="copy_residence"
                    :countries-list="countriesList"
                    :selectedCountry.sync="selectedCountryResidence"
                    :selectedRegion.sync="selectedRegionResidence"
                    :selectedState.sync="selectedStateResidence"
                    :selectedCity.sync="selectedCityResidence"
                    :disabled="disabled"
                ></LocationBoxes>
                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field
                        v-model="singleItem.residence_address"
                        ret
                        filled
                        dense
                        :disabled="disabled"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.ADDRESS')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                        v-model="singleItem.residence_postal_code"
                        filled
                        dense
                        :disabled="disabled"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.ZIP')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="singleItem.phone"
                        filled
                        dense
                        :disabled="disabled"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.PHONE')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="singleItem.mobile_phone"
                        filled
                        dense
                        :disabled="disabled"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.MOBILE_PHONE')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="singleItem.email"
                        filled
                        dense
                        :disabled="disabled"
                        color="blue-grey lighten-2"
                        :label="$t('PAGES.CITIZENS.FORM.MANAGE.EMAIL')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-row>
                  <v-col cols="12" md="2" offset="10">
                    <v-btn color="success" class="mr-4" @click="validate">
                      {{ $t("COMMON.BUTTON.SAVE") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <div style="flex: 1 1 auto"></div>
          </b-card-body>
        </v-card>
      </div>
    </div>
    <DialogMessage :vars="dialogVars" :dialog.sync="showDialog"></DialogMessage>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import rules from "@/core/helper/rules.helper.js";
import general from "@/core/helper/general.helper.js";
import config from "@/core/config/general.config.json";

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import {FETCH_COUNTRIES_LIST} from "@/core/services/store/countries.module";
import {
  FETCH_CITIZENS_CHECK,
  UPDATE_CITIZEN_DATA,
  GET_CITIZEN_BY_ID
} from "@/core/services/store/citizens.module";
import DialogMessage from "@/view/commons/DialogMessage";
import DateBox from "@/view/commons/forms/DateBox";
import LocationBoxes from "@/view/commons/forms/LocationBoxes";

export default {
  name: "CitizensNew",
  data() {
    return {
      showDialog: false,
      dialogVars: {
        area: {},
        message: {
          title: String,
          text: String,
        },
        type: String,
        route: false
      },
      foreign_country: false,
      edit_form_typology: false,
      items: ["foo", "bar", "fizz", "buzz"],
      resident: false,
      residenceStartDate: '',
      copy_residence: false,
      filtersVat: {
        query: [],
        filters: {
          vat: {
            _eq: {},
          },
        },
        sort: [],
        page: 1,
        limit: 1,
      },
      companyCopyData: {},
      user: {},
      valid: true,
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      filtersFiscalCode: {
        query: [],
        filters: {
          fiscalCode: {
            _eq: {},
          },
        },
        sort: [],
        page: 1,
        limit: 1,
      },
      users_filters: {
        query: [],
        filters: {
          _c1: {
            "member_group.is_banned": {
              _eq: false,
            },
          },
          _c2: {
            "member_group.is_admin": {
              _eq: true,
            },
          },

          _cmb_or: "_c1,_c2",
        },
        sort: {},
        page: 1,
      },
      positionValues: [
        {
          value: 1,
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.POSITION_1"),
        },
        {
          value: 2,
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.POSITION_2"),
        },
        {
          value: 3,
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.POSITION_3"),
        }
      ],
      status: [
        {
          value: false,
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.NOT_ACTIVE"),
        },
        {
          value: true,
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.ACTIVE"),
        },
      ],
      simpleValues: [
        {
          value: "1",
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.YES"),
        },
        {
          value: "0",
          name: this.$t("PAGES.CITIZENS.FORM.MANAGE.NOT"),
        },
      ],

      isLoadingCountries: false,
      isLoadingRegions: false,
      isLoadingStates: false,
      isLoadingCities: false,

      selectedCountryBirth: Number,
      // selectedRegionBirth: Number,
      // selectedStateBirth: Number,
      // selectedCityBirth: Number,

      // selectedCountryResidence: Number,
      // selectedRegionResidence: Number,
      // selectedStateResidence: Number,
      // selectedCityResidence: Number,

      selectedCountryDomicile: Number,
      // selectedRegionDomicile: Number,
      // selectedStateDomicile: Number,
      // selectedCityDomicile: Number,
      requiredRule: [(v) => {
        return rules.requiredRules(v);
      }],
      fiscalCodeRule: [(v) => {
        if (!v) return this.$t("COMMON.ERRORS.REQUIRED");
        return rules.fiscalCodeRule(this.fiscalCodeUsed);
      }],
    };
  },
  computed: {
    ...mapGetters([
      "memberGroupsList",
      "regUsersList",
      "countriesList",
      "fullStateList",
      "citizenInCheck",
      "citizenSaveLoading",
      "getCitizenApiErrors",
      "getSavingSuccess",
      "citizenInUse",
    ]),
    selectedAbroadCountryBirth: function () {
      if (this.singleItem.bornAbroad != undefined && this.singleItem.bornAbroad.id != undefined) {
        return this.singleItem.bornAbroad.id;
      }
      return null;
    },
    selectedAbroadCountryResidence: function () {
      if (this.singleItem.residenceAbroad != undefined && this.singleItem.residenceAbroad.id != undefined) {
        return this.singleItem.residenceAbroad.id;
      }
      return null;
    },
    selectedRegionBirth: function () {
      if (this.singleItem.city_of_birth != undefined && this.singleItem.city_of_birth.state != undefined) {
        return this.singleItem.city_of_birth.state.region.id;
      }
      return null;
    },
    selectedStateBirth: function () {
      if (this.singleItem.city_of_birth != undefined && this.singleItem.city_of_birth.state != undefined) {
        return this.singleItem.city_of_birth.state.id;
      }
      return null;
    },
    selectedCityBirth: function () {
      if (this.singleItem.city_of_birth != undefined && this.singleItem.city_of_birth.state != undefined) {
        return this.singleItem.city_of_birth.id;
      }
      return null;
    },
    // selectedCountryResidence: function () {
    //   return this.singleItem.city_of_residence.state.region.country.id
    // },
    selectedRegionResidence: function () {
      if (this.singleItem.city_of_residence != undefined && this.singleItem.city_of_residence.state != undefined) {
        return this.singleItem.city_of_residence.state.region.id;
      }
      return null;
    },
    selectedStateResidence: function () {
      if (this.singleItem.city_of_residence != undefined && this.singleItem.city_of_residence.state != undefined) {
        return this.singleItem.city_of_residence.state.id;
      }
      return null;
    },
    selectedCityResidence: function () {
      if (this.singleItem.city_of_residence != undefined) {
        return this.singleItem.city_of_residence.id;
      }
      return null;
    },
    selectedRegionDomicile: function () {
      if (this.singleItem.city_of_domicile != undefined && this.singleItem.city_of_domicile.state != undefined) {
        return this.singleItem.city_of_domicile.state.region.id;
      }
      return null;
    },
    selectedStateDomicile: function () {
      if (this.singleItem.city_of_domicile != undefined && this.singleItem.city_of_domicile.state != undefined) {
        return this.singleItem.city_of_domicile.state.id;
      }
      return null;
    },
    selectedCityDomicile: function () {
      if (this.singleItem.city_of_domicile != undefined && this.singleItem.city_of_domicile.state != undefined) {
        return this.singleItem.city_of_domicile.id;
      }
      return null;
    },
    residenceBirth: function () {
      if (this.singleItem.bornAbroad != undefined && this.singleItem.bornAbroad.id != undefined) {
        return this.singleItem.bornAbroad.id;
      }
      return null;
    },
    residenceAbroad: function () {
      if (this.singleItem.residenceAbroad != undefined && this.singleItem.residenceAbroad.id != undefined) {
        return this.singleItem.residenceAbroad.id;
      }
      return null;
    },
    bornAbroad: function () {
      if (this.singleItem.bornAbroad != undefined && this.singleItem.bornAbroad.id != undefined) {
        return this.singleItem.bornAbroad.id;
      }
      return null;
    },
    cityOfResidence: function () {
      if (this.singleItem.cityOfResidence != undefined) {
        return this.singleItem.cityOfResidence;
      }
      return null;
    },
    domicileAbroad: function () {
      if (this.singleItem.domicileAbroad != undefined && this.singleItem.domicileAbroad.id != undefined) {
        return this.singleItem.domicileAbroad.id;
      }
      return null;
    },
    cityOfDomicile: function () {
      if (this.singleItem.cityOfDomicile != undefined) {
        return this.singleItem.cityOfDomicile;
      }
      return null;
    },
    singleItem() {
      return this.citizenInUse != undefined ? this.citizenInUse : {};
    },
    fiscalCodeUsed() {
      if (
          this.citizenInCheck != undefined &&
          this.citizenInCheck[0] != undefined
      ) {
        return this.citizenInCheck[0].fiscalCode == this.singleItem.fiscalCode ? true : false;
      }
      return false;
    },
    dateOfDeathFormatted() {
      return general.dateFormatter(this.singleItem.dateOfDeath)
    },
    dateOfBirthFormatted() {
      return general.dateFormatter(this.singleItem.dateOfBirth)
    },
    residenceStartDateFormatted() {
      return general.dateFormatter(this.singleItem.residenceStartDate)
    },
    dateOfEmigrationFormatted() {
      return general.dateFormatter(this.singleItem.dateOfEmigration)
    },
  },
  components: {DialogMessage, DateBox, LocationBoxes},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.CITIZENS.AREA")},
      {
        title: this.edit_form_typology
            ? this.$t("PAGES.CITIZENS.EDIT.TITLE")
            : this.$t("PAGES.CITIZENS.NEW.TITLE_PERSON"),
      },
    ]);
    this.$store.dispatch(FETCH_COUNTRIES_LIST, this.empty_filters);
    this.$store.dispatch(GET_CITIZEN_BY_ID, this.$route.params.id);

  },
  watch: {
    // selectedCityBirth: function () {
    //   this.singleItem.cityOfBirth = this.selectedCityBirth
    // },
    // selectedCityResidence: function () {
    //   this.singleItem.cityOfResidence = this.selectedCityResidence
    // },
    // selectedCityDomicile: function () {
    //   this.singleItem.cityOfDomicile = this.selectedCityDomicile
    // },
    resident: function () {
      // this.$refs.changeCountryResidence.reset()
      this.singleItem.resident = this.resident
      this.selectedCountryResidence = config.location.country
      this.selectedRegionResidence = config.location.region
      this.selectedStateResidence = config.location.state
      this.selectedCityResidence = config.location.city
    },
    copy_residence: function () {
      if (this.copy_residence == true) {
        this.selectedCountryDomicile = this.selectedCountryResidence
        this.selectedRegionDomicile = this.selectedRegionResidence
        this.selectedStateDomicile = this.selectedStateResidence
        this.selectedCityDomicile = this.selectedCityResidence
        this.singleItem.domicileAddress = this.singleItem.residenceAddress
        this.singleItem.domicilePostalCode = this.singleItem.residencePostalCode
      }
    },
    getCitizenApiErrors: function () {
      this.dialogVars.message.title = this.$t(
          "PAGES.CITIZENS.COMMON.ERROR_ONSAVE"
      );
      this.dialogVars.message.text = this.getCitizenApiErrors;
      this.dialogVars.type = 'error';
      this.showDialog = true;
    },
    getSavingSuccess: function () {
      this.dialogVars.message.title = this.$t(
          "COMMON.SUCCESS.TITLE"
      );
      this.dialogVars.message.text = this.getCitizenApiErrors;
      this.dialogVars.type = 'success';
      this.dialogVars.route = 'citizens_list';
      this.showDialog = true;

    },
    // selectedCountryResidence: function () {
    //   if (this.singleItem.cityOfResidence != undefined || this.singleItem.residenceAbroad != undefined) {
    //     if (this.singleItem.residenceAbroad != undefined && this.singleItem.residenceAbroad.id != undefined) {
    //       return this.singleItem.residenceAbroad.id;
    //     } else {
    //       this.singleItem.residenceAbroad.delete()
    //       if (this.singleItem.cityOfResidence.state != undefined) {
    //         console.log('this.singleItem.cityOfResidence.state.region.country.id', this.singleItem.cityOfResidence.state.region.country.id)
    //         return this.singleItem.cityOfResidence.state.region.country.id
    //       }
    //     }
    //   }
    //   return null;
    // },
    citizenInUse: function () {
      if (this.singleItem.city_of_birth != undefined && this.singleItem.bornAbroad instanceof Array) {
        this.selectedCountryBirth = this.singleItem.city_of_birth.state.region.country.id
      } else {
        this.selectedCountryBirth = this.bornAbroad
      }
      if (this.singleItem.city_of_residence != undefined && this.singleItem.residenceAbroad instanceof Array) {
        this.selectedCountryResidence = this.singleItem.city_of_residence.state.region.country.id
      } else {
        this.selectedCountryResidence = this.residenceAbroad
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.new_citizen_form.validate()) {
        this.elaborateForeign()
        this.snackbar = true;
        this.user.status = 1;
        this.save();
      }
    },
    elaborateForeign() {
      if (this.selectedCountryBirth != 380) {
        this.singleItem.city_of_birth = {};
        // this.$delete(this.singleItem, "city_of_birth");
        this.singleItem.bornAbroad = this.selectedCountryBirth;
      }
      if (this.selectedCountryResidence != 380) {
        // this.$delete(this.singleItem, "city_of_residence");
        this.singleItem.city_of_residence = {};
        this.singleItem.residenceAbroad = this.selectedCountryResidence;
      }
      if (this.selectedCountryDomicile != 380) {
        // this.$delete(this.singleItem, "city_of_domicile");
        this.singleItem.city_of_domicile = {};
        this.singleItem.domicileAbroad = this.selectedCountryDomicile;
      }
    },
    checkFiscalCode() {
      this.filtersFiscalCode.filters.fiscalCode._eq = this.singleItem.fiscalCode;
      this.$store.dispatch(FETCH_CITIZENS_CHECK, this.filtersFiscalCode);
    },
    save() {
      var citizenToUpdate = Object.assign({}, this.singleItem);
      if (citizenToUpdate instanceof Array) {
        citizenToUpdate.city_of_domicile = null;
        // citizenToUpdate.domicileAbroad = this.singleItem.selectedCountryDomicile.id
      } else {
        citizenToUpdate.city_of_domicile = citizenToUpdate.city_of_domicile.id
        // this.$delete(citizenToUpdate, "domicileAbroad");
      }
      if (citizenToUpdate.city_of_birth instanceof Array) {
        citizenToUpdate.city_of_birth = null;
        // citizenToUpdate.bornAbroad = this.singleItem.selectedCountryBirth.id
      } else {
        citizenToUpdate.city_of_birth = citizenToUpdate.city_of_birth.id;
        // this.$delete(citizenToUpdate, "bornAbroad");
      }
      if (citizenToUpdate.city_of_residence instanceof Array) {
        citizenToUpdate.city_of_residence = null;
        // citizenToUpdate.residenceAbroad = this.singleItem.selectedCountryResidence.id
      } else {
        citizenToUpdate.city_of_residence = citizenToUpdate.city_of_residence.id
        // this.$delete(citizenToUpdate, "residenceAbroad");
      }

      this.$delete(citizenToUpdate, "updatedAt");
      this.$delete(citizenToUpdate, "createdAt");
      this.$delete(citizenToUpdate, "notes");
      this.$delete(citizenToUpdate, "reservations");
      this.$store.dispatch(UPDATE_CITIZEN_DATA, citizenToUpdate);
    }
  },
  created() {
    if (this.$route.params.id != undefined) {
      this.edit_form_typology = true;
    }
  }
};
</script>
