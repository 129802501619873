/**
 * Service Rules
 */

import i18n from "@/core/plugins/vue-i18n";
const rulesHelper = {
  emailRules: function (v) {
    if (v == undefined)
      return i18n.t("PAGES.CITIZENS.ATTRIBUTES.EMAIL_REQUIRED");
    return (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      i18n.t("PAGES.CITIZENS.ATTRIBUTES.EMAIL_ERROR")
    );
  },
  denominationRule: function (v) {
    if (v == undefined)
      return i18n.t("PAGES.CITIZENS.ATTRIBUTES.DENOMINATION_REQUIRED");

    return (
      (v && v.length > 2) ||
      i18n.t("PAGES.CITIZENS.ATTRIBUTES.MIN_LENGHT_DENOMINATION")
    );
  },
  requiredRules: function (v) {
    if (!v) return i18n.t("COMMON.ERRORS.REQUIRED");
    return true;
  },
  requiredSelectRules: function (v) {
    return (
      (v != undefined && v.length > 0) || i18n.t("COMMON.ERRORS.REQUIRED")
    );
  },
  fiscalCodeRule: function (v) {
    if (v) {
      return i18n.t("PAGES.CITIZENS.FORM.MANAGE.ERROR_FISCAL_CODE_USED");
    }
    return true;
  },
  numberRule: function (v) {
    if (!isNaN(parseInt(v)) && v.length == 11) return true;
    return i18n.t("PAGES.CITIZENS.FORM.MANAGE.ERROR_VAT");
  },
  isNumber: function (evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
      return false;
    } else {
      return true;
    }
  },
};
export default rulesHelper;
